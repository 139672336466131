// @import-normalize;
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // outline: 1px solid tomato;
}

:root {
  --main-color: #ed3f23;
  --text-color: #0f1539;
  --success-color: #23c84d;
  --failure-color: #ee3f23;
  --inactive-color: #bfbfbf;
  --main-font: "Nunito", sans-serif;
  --shadow: 0px 6px 15px rgba(0, 0, 0, 0.4);
}

body {
  margin: 0;
  font-family: var(--main-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: normal;
}
button {
  all: unset;
}
button,
input,
optgroup,
select,
textarea {
  font-family: var(--main-font);
}
p,
span {
  margin: 0;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  display: block;
  margin: 0;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: var(--main-color);
}

article {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 100;
}

@media screen and (max-width: 767.99px) {
}
@media screen and (min-width: 768px) {
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
}

@media screen and (min-width: 1280px) {
}
