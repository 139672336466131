.Header {
}
.Header__container {
  padding: 20px 80px;
  border-bottom: 1px solid var(--main-color);
  width: 100%;
}

@media screen and (max-width: 767.99px) {
  .Header {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 768px) {
  .Header__logo {
    width: 200px;
  }
  .Header__container {
    padding-left: 0;
  }
  .Header {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
}

@media screen and (min-width: 1280px) {
}
