.App {
}

.App {
  @media screen and (max-width: 767px) {
    width: 100%;
    width: 320px;
    padding: 10px;
    padding-bottom: 30px;
    padding-top: 0;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (min-width: 768px) {
    text-align: justify;

    padding: 30px;
    padding-top: 0;
  }

  @media screen and (min-width: 768px) and (max-width: 1279px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 1280px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;
    padding: 50px;
    padding-top: 0;
  }
}
